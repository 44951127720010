var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Jumbotron", { attrs: { title: "Chats" } }),
      _c("DataTable", {
        attrs: {
          items: _vm.chatMessages,
          fields: [
            {
              key: "guestName",
              label: _vm.$t("tables.chatMessages.guestName"),
              sorter: true,
              filter: false
            },
            {
              key: "lastMessageAt",
              label: _vm.$t("tables.chatMessages.lastMessageAt"),
              sorter: true,
              filter: false
            },
            {
              key: "unreadMessages",
              label: _vm.$t("tables.chatMessages.unreadMessages"),
              sorter: true,
              filter: false
            },
            {
              key: "actions",
              label: _vm.$t("tables.common.actions"),
              sorter: false,
              filter: false
            }
          ],
          "table-filter-value": _vm.tableFilterValue,
          "sorter-value": _vm.sorterValue,
          loading: _vm.loading
        },
        on: {
          "row-clicked": _vm.onRowClick,
          "update:tableFilterValue": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValue = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValue = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "lastMessageAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", {
                      attrs: {
                        date: _vm.parseDate(item.lastMessageAt),
                        displayFormat: "P | p"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "HostChatMessageDetail",
                            params: { guestId: item.guestId }
                          }
                        }
                      },
                      [_vm._v(" Öffnen ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm.pages > 1
        ? _c("CPagination", {
            attrs: { pages: _vm.pages, activePage: _vm.currentPage },
            on: {
              "update:activePage": function($event) {
                _vm.currentPage = $event
              },
              "update:active-page": function($event) {
                _vm.currentPage = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }